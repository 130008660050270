import { memo, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGlobalVars } from '../../../../App';
import { downloadHTML } from '../../../util/file_download';
import { title } from '../../../util/string_methods';

function highlightAIText(text, highlights) {
  let formattedText = '';
  highlights.forEach((highlight) => {
    let index = text.indexOf(highlight);
    if (index > 0) formattedText += `<span>${text.slice(0, index)}</span>`;
    if (index >= 0) {
      formattedText += `<mark>${highlight}</mark>`;
      text = text.slice(index + highlight.length);
    }
  });
  if (text.length > 0) formattedText += `<span>${text}</span>`;
  return formattedText;
}

function onDownload(row, html, dt, cName, aName) {
  const fileName = `${row.s}-text_inspection`;
  const body = `
<h3 style="color: #1e4d2b;" class="m-2">
  <a style="color: inherit; text-decoration: none" href='https://ramdesk.colostate.edu'>RamDesk</a>
</h3>
<div class="p-1 border rounded m-1">
  <b>Inspection Details</b>
  <ul>
    <li><b>Course Name: </b>${cName}</li>
    <li><b>Assignment Name: </b>${aName}</li>
    <li><b>Inspection Date: </b>${dt}</li>
  </ul>
</div>
<div class="p-1 border rounded m-1">
  <b>Submission Details</b>
  <ul>
    <li><b>Student Name: </b>${title(row.s.replaceAll('_', ' '))}</li>
    <li><b>File Name: </b>${row.f}</li>
    <li><b>Total Words: </b>${row.w}</li>
    <li><b>% Likely AI Generated: </b>${row.p}</li>
  </ul>
</div>
<hr />
<div class="px-2">
  ${html}
</div>`;
  downloadHTML(body, fileName);
}

function ReportModal({ row, dt }) {
  const { setShowModal, activeCourse, activeAssignment } = useGlobalVars();
  const [fullScreen, setFullScreen] = useState(false);
  const textHTML = highlightAIText(row.t, row.h);

  return (
    <Modal
      size='xl'
      fullscreen={fullScreen}
      scrollable
      show
      centered
      onHide={() => setShowModal(null)}
    >
      <Modal.Header className='d-flex align-items-center bg-primary text-light p-2 fw-bold'>
        <div className='flex-grow-1'>{`${title(row.s.replaceAll('_', ' '))} - ${row.f}`}</div>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>Fullscreen</Tooltip>}
        >
          <i
            className='bi bi-arrows-fullscreen text-light me-3 pointer'
            onClick={() => setFullScreen(!fullScreen)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>Download as HTML file</Tooltip>}
        >
          <i
            className='bi bi-download text-light me-3 pointer'
            onClick={() =>
              onDownload(
                row,
                textHTML,
                dt,
                activeCourse.name,
                activeAssignment.name,
              )
            }
          />
        </OverlayTrigger>
        <i className='bi bi-x-lg pointer' onClick={() => setShowModal(null)} />
      </Modal.Header>
      <Modal.Body className='small'>
        <div dangerouslySetInnerHTML={{ __html: textHTML }} />
      </Modal.Body>
    </Modal>
  );
}

export default memo(ReportModal);
