import * as ed from './ExtendDocs';
import * as gd from './GradeDocs';
import * as id from './InspectDocs';
import * as md from './MiscDocs';
import * as nd from './NudgeDocs';

export const REGISTRY = [
  {
    key: 'home',
    component: <md.Home />,
    children: null,
    path: 'home',
  },
  {
    key: 'breadcrumbs',
    component: <md.Breadcrumbs />,
    children: null,
    path: 'breadcrumbs',
  },
  {
    key: 'courses',
    component: <md.Courses />,
    children: null,
    path: 'courses',
  },
  {
    key: 'course',
    component: <md.Course />,
    children: null,
    path: 'course',
  },
  {
    key: 'extend',
    component: <ed.ExtendGeneral />,
    path: 'extend',
    children: [
      {
        key: 'dashboard',
        component: <ed.ExtendDashboard />,
        path: 'extend/dashboard',
        children: null,
      },
      {
        key: 'statistics',
        component: <ed.ExtendStatistics />,
        path: 'extend/statistics',
        children: null,
      },
      {
        key: 'extra_days',
        component: <ed.ExtendExtraDays />,
        path: 'extend/extra_days',
        children: null,
      },
      {
        key: 'extra_time',
        component: <ed.ExtendExtraTime />,
        path: 'extend/extra_time',
        children: null,
      },
      {
        key: 'assignment_eligibility',
        component: <ed.ExtendAssignmentEligibility />,
        path: 'extend/assignment_eligibility',
        children: null,
      },
      {
        key: 'quiz_eligibility',
        component: <ed.ExtendQuizEligibility />,
        path: 'extend/quiz_eligibility',
        children: null,
      },
      {
        key: 'automatic_updates',
        component: <ed.ExtendAutomaticUpdates />,
        path: 'extend/automatic_updates',
        children: null,
      },
      {
        key: 'best_practices',
        component: <ed.ExtendBestPractices />,
        path: 'extend/best_practices',
        children: null,
      },
    ],
  },
  {
    key: 'nudge',
    component: <nd.NudgeGeneral />,
    path: 'nudge',
    children: [
      {
        key: 'statistics',
        component: <nd.NudgeStatistics />,
        path: 'nudge/statistics',
        children: null,
      },
      {
        key: 'dashboard',
        component: <nd.NudgeDashboard />,
        path: 'nudge/dashboard',
        children: null,
      },
      {
        key: 'target_date',
        component: <nd.NudgeTargetDate />,
        path: 'nudge/target_date',
        children: null,
      },
      {
        key: 'target_student',
        component: <nd.NudgeTargetStudent />,
        path: 'nudge/target_student',
        children: null,
      },
      {
        key: 'students',
        component: <nd.NudgeStudents />,
        path: 'nudge/students',
        children: null,
      },
      {
        key: 'assignments',
        component: <nd.NudgeAssignments />,
        path: 'nudge/assignments',
        children: null,
      },
      {
        key: 'message',
        component: <nd.NudgeMessage />,
        path: 'nudge/message',
        children: null,
      },
      {
        key: 'sample',
        component: <nd.NudgeSample />,
        path: 'nudge/sample',
        children: null,
      },
    ],
  },
  {
    key: 'grade',
    component: <gd.GradeGeneral />,
    path: 'grade',
    children: [
      {
        key: 'dashboard',
        component: <gd.GradeDashboard />,
        path: 'grade/dashboard',
        children: null,
      },
      {
        key: 'statistics',
        component: <gd.GradeStatistics />,
        path: 'grade/statistics',
        children: null,
      },
      {
        key: 'logs',
        component: <gd.GradeLogs />,
        path: 'grade/logs',
        children: [
          {
            key: 'filter',
            component: <gd.GradeLogsFilter />,
            path: 'grade/logs/filter',
            children: null,
          },
          {
            key: 'table',
            component: <gd.GradeLogsTable />,
            path: 'grade/logs/table',
            children: null,
          },
        ],
      },
      {
        key: 'config',
        component: <gd.GradeConfig />,
        path: 'grade/config',
        children: [
          {
            key: 'type',
            component: <gd.GradeConfigType />,
            path: 'grade/config/type',
            children: null,
          },
          {
            key: 'networking',
            component: <gd.GradeConfigNetworking />,
            path: 'grade/config/networking',
            children: null,
          },
          {
            key: 'canvas_grade',
            component: <gd.GradeConfigCanvasGrade />,
            path: 'grade/config/canvas_grade',
            children: null,
          },
          {
            key: 'canvas_comment',
            component: <gd.GradeConfigCanvasComment />,
            path: 'grade/config/canvas_comment',
            children: null,
          },
          {
            key: 'versions',
            component: <gd.GradeConfigVersions />,
            path: 'grade/config/versions',
            children: null,
          },
          {
            key: 'grading_assets',
            component: <gd.GradeConfigAssets />,
            path: 'grade/config/grading_assets',
            children: null,
          },
        ],
      },
      {
        key: 'tasks',
        component: <gd.GradeTask />,
        path: 'grade/tasks',
        children: [
          {
            key: 'extract',
            component: <gd.GradeTaskExtract />,
            path: 'grade/tasks/extract',
            children: null,
          },
          {
            key: 'check_file',
            component: <gd.GradeTaskCheckFile />,
            path: 'grade/tasks/check_file',
            children: null,
          },
          {
            key: 'check_code',
            component: <gd.GradeTaskCheckCode />,
            path: 'grade/tasks/check_code',
            children: null,
          },
          {
            key: 'static_analysis',
            component: <gd.GradeTaskStaticAnalysis />,
            path: 'grade/tasks/static_analysis',
            children: null,
          },
          {
            key: 'make',
            component: <gd.GradeTaskMake />,
            path: 'grade/tasks/make',
            children: null,
          },
          {
            key: 'jupyter',
            component: <gd.GradeTaskJupyter />,
            path: 'grade/tasks/jupyter',
            children: null,
          },
          {
            key: 'run_grader',
            component: <gd.GradeTaskCmd />,
            path: 'grade/tasks/run_grader',
            children: [
              {
                key: 'custom_script',
                component: <gd.GradeTaskCmdCustom />,
                path: 'grade/tasks/run_grader/custom_script',
                children: null,
              },
              {
                key: 'table_grading',
                component: <gd.GradeTaskCmdTable />,
                path: 'grade/tasks/run_grader/table_grading',
                children: null,
              },
            ],
          },
        ],
      },
      {
        key: 'test',
        component: <gd.GradeSubmissionTest />,
        path: 'grade/test',
        children: null,
      },
      {
        key: 'trigger',
        component: <gd.GradeTriggerGrading />,
        path: 'grade/trigger',
        children: null,
      },
    ],
  },
  {
    key: 'inspect',
    component: <id.InspectGeneral />,
    path: 'inspect',
    children: [
      {
        key: 'dashboard',
        component: <id.InspectDashboard />,
        path: 'inspect/dashboard',
        children: null,
      },
      {
        key: 'statistics',
        component: <id.InspectStatistics />,
        path: 'inspect/statistics',
        children: null,
      },
      {
        key: 'code',
        component: <id.InspectCode />,
        path: 'inspect/code',
        children: [
          {
            key: 'run_code',
            component: <id.InspectRunCode />,
            path: 'inspect/code/run_code',
            children: null,
          },
          {
            key: 'view_code_inspection',
            component: <id.InspectViewCodeInspection />,
            path: 'inspect/code/view_code_inspection',
            children: null,
          },
          {
            key: 'view_code_pairs',
            component: <id.InspectViewCodePairs />,
            path: 'inspect/code/view_code_pairs',
            children: null,
          },
        ],
      },
      {
        key: 'text',
        component: <id.InspectText />,
        path: 'inspect/text',
        children: [
          {
            key: 'run_text',
            component: <id.InspectRunText />,
            path: 'inspect/text/run_text',
            children: null,
          },
          {
            key: 'view_text_inspection',
            component: <id.InspectViewTextInspection />,
            path: 'inspect/text/view_text_inspection',
            children: null,
          },
          {
            key: 'view_text_flagged',
            component: <id.InspectViewTextFlagged />,
            path: 'inspect/text/view_text_flagged',
            children: null,
          },
        ],
      },
    ],
  },
  {
    key: 'bug_report',
    component: <md.BugReport />,
    path: 'bug_report',
    children: null,
  },
  {
    key: 'unsubscribe',
    component: <md.Unsubscribe />,
    path: 'unsubscribe',
    children: null,
  },
];
