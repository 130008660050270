import he from 'he';
import { memo, useRef, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGlobalVars } from '../../../../App';
import { downloadHTML } from '../../../util/file_download';
import { title } from '../../../util/string_methods';
import { CODE_COLORS } from '../Constants';

function onDownload(modalRef, row, dt, cName, aName) {
  const fileName = `${row.s0}-${row.s1}-code_inspection`;
  const body = `
<h3 style="color: #1e4d2b;" class="m-2">
  <a style="color: inherit; text-decoration: none" href='https://ramdesk.colostate.edu'>RamDesk</a>
</h3>
<div class="p-1 border rounded m-1">
  <b>Inspection Details</b>
  <ul>
    <li><b>Course Name: </b>${cName}</li>
    <li><b>Assignment Name: </b>${aName}</li>
    <li><b>Inspection Date: </b>${dt}</li>
  </ul>
</div>
<div class="d-flex p-1" style="height: 80vh;">
  ${modalRef.current.innerHTML}
</div>`;
  downloadHTML(body, fileName);
}

function MatchCodeBlock({ codeRefs, result, idx }) {
  const [isHovered, setIsHovered] = useState(false);

  function handleOnClick() {
    const oppositeRef = codeRefs.current[result.id][idx ^ 1];
    oppositeRef.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
    oppositeRef.style.boxShadow = '0 0 5px 1px gold inset';
    setTimeout(() => {
      oppositeRef.style.boxShadow = '';
    }, 1000);
  }

  function handleMouseEvent() {
    setIsHovered((prev) => !prev);
  }

  return (
    <pre
      ref={(el) => (codeRefs.current[result.id][idx] = el)}
      style={{
        backgroundColor: codeRefs.current[result.id].color,
        filter: isHovered ? 'brightness(0.9)' : '',
      }}
      onClick={handleOnClick}
      onMouseEnter={handleMouseEvent}
      onMouseLeave={handleMouseEvent}
      className='pointer m-0'
    >
      {he.decode(result.c)}
    </pre>
  );
}

function CodeDisplay({ codeRefs, result, idx }) {
  const lines = result[`c${idx}`].reduce((acc, item) => {
    return (acc += (item.c.match(/\n/g) || []).length);
  }, 1);

  return (
    <div className='w-50 h-100 p-1'>
      <div
        className='h-100 border rounded d-flex flex-column'
        style={{ maxHeight: 'calc(100% - 1.5rem)' }}
      >
        <div className='flex-shrink-0 d-flex bg-light fw-semibold py-1 px-2 border-bottom'>
          <span className='truncateTxt'>
            {title(result[`s${idx}`].replaceAll('_', ' '))}
          </span>
          <span className='vr mx-2' />
          <span className='truncateTxt flex-grow-1'>{result[`f${idx}`]}</span>
          <span className='vr mx-2' />
          <span>{result[`p${idx}`]}%</span>
        </div>
        <div className='flex-grow-1 d-flex p-1 overflow-y-auto'>
          <div className='small me-2 text-muted'>
            {Array(lines)
              .fill()
              .map((_, i) => (
                <div key={i}>{i + 1}</div>
              ))}
          </div>
          <div>
            {result[`c${idx}`].map((x, i) => {
              if (x.id === -1)
                return (
                  <pre key={i} className='m-0'>
                    {he.decode(x.c)}
                  </pre>
                );
              else
                return (
                  <MatchCodeBlock
                    key={i}
                    codeRefs={codeRefs}
                    result={x}
                    idx={idx}
                  />
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function ReportModal({ row, dt }) {
  const { setShowModal, activeCourse, activeAssignment } = useGlobalVars();
  const [fullScreen, setFullScreen] = useState(false);
  const modalRef = useRef(null);
  let codeRefs = useRef(
    row.c0.reduce((acc, item) => {
      if (item.id !== -1)
        acc[item.id] = {
          0: null,
          1: null,
          color: CODE_COLORS[item.id % CODE_COLORS.length],
        };
      return acc;
    }, {}),
  );

  return (
    <Modal
      size='xl'
      fullscreen={fullScreen}
      scrollable
      show
      centered
      onHide={() => setShowModal(null)}
    >
      <Modal.Header className='d-flex align-items-center bg-primary text-light p-2 fw-bold'>
        <div className='flex-grow-1'>{`Code Comparison`}</div>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>Fullscreen</Tooltip>}
        >
          <i
            className='bi bi-arrows-fullscreen text-light me-3 pointer'
            onClick={() => setFullScreen(!fullScreen)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>Download as HTML file</Tooltip>}
        >
          <i
            className='bi bi-download text-light me-3 pointer'
            onClick={() =>
              onDownload(
                modalRef,
                row,
                dt,
                activeCourse.name,
                activeAssignment.name,
              )
            }
          />
        </OverlayTrigger>
        <i className='bi bi-x-lg pointer' onClick={() => setShowModal(null)} />
      </Modal.Header>
      <div
        ref={modalRef}
        className='d-flex small p-1'
        style={{ height: fullScreen ? '96vh' : '90vh' }}
      >
        <CodeDisplay codeRefs={codeRefs} result={row} idx={0} />
        <CodeDisplay codeRefs={codeRefs} result={row} idx={1} />
      </div>
    </Modal>
  );
}

export default memo(ReportModal);
