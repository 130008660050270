import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getStaticAnalysisReport } from '../../api/static_analysis_api';
import {
  CodeChecks,
  CodeComplexity,
  CodeMetrics,
  CodeSmells,
} from '../docs/GradeDocs';
import ExpandableCard from '../reusable/ExpandableCard';
import LoadingSpinner from '../reusable/LoadingSpinner';

function Explanation({ children }) {
  return <div className='bg-light p-3 rounded-2 border mb-3'>{children}</div>;
}

function Report({ report }) {
  return (
    <div className='p-3'>
      <Explanation>
        <CodeComplexity />
      </Explanation>
      <ExpandableCard open>
        <div>Code Metrics</div>
        <div className='p-3'>
          <Explanation>
            <CodeMetrics />
          </Explanation>
          {report.metrics ? (
            <pre>{report.metrics}</pre>
          ) : (
            <p>Unable to compute code metrics</p>
          )}
        </div>
      </ExpandableCard>
      <ExpandableCard open>
        <div>Code Smells</div>
        <div className='p-3'>
          <Explanation>
            <CodeSmells />
          </Explanation>
          {report.smells ? (
            <pre>{report.smells}</pre>
          ) : (
            <p>No code smells detected</p>
          )}
        </div>
      </ExpandableCard>
      <ExpandableCard open>
        <div>Code Checks</div>
        <div className='p-3'>
          <Explanation>
            <CodeChecks />
          </Explanation>
          {report.checks ? (
            <pre>{report.checks}</pre>
          ) : (
            <p>No issues detected</p>
          )}
        </div>
      </ExpandableCard>
    </div>
  );
}

function StaticAnalysis() {
  const { token } = useParams();
  const [report, setReport] = useState(undefined);

  useEffect(() => {
    getStaticAnalysisReport(token)
      .then(setReport)
      .catch(() => setReport(null));
  }, []);

  if (report === undefined) return <LoadingSpinner text='Loading Report...' />;
  else if (report === null)
    return (
      <div className='text-center m-auto p-5'>
        <h1 className='text-primary'>Invalid Report ID</h1>
      </div>
    );
  else return <Report report={report} />;
}

export default StaticAnalysis;
