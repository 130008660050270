import axios from 'axios';
import { addCache } from '../util/cache';
import { BACKEND_URL } from './api';

const STATIC_ANALYSIS_CACHE_ID = 'static-analysis-cache-id';

function getCacheId(token) {
  return STATIC_ANALYSIS_CACHE_ID + '-' + token;
}

export function getStaticAnalysisReport(token) {
  return axios
    .get(BACKEND_URL + '/static-analysis/' + token, {
      id: getCacheId(token),
    })
    .then((res) => res.data);
}

export function setStaticAnalysisReportCache(report) {
  addCache(getCacheId(report.id), report);
}
