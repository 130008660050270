import React, { memo } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Report from '../interface/Report';
import Breadcrumbs from './Breadcrumbs';

function Header({ name, picture, role }) {
  const navigate = useNavigate();

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <Button
      className='d-flex align-items-center mx-2 border-0'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Image
        src={picture}
        roundedCircle
        className='border border-2 border-light'
        height={'35px'}
      />
      <span className='small ms-2 d-none d-md-block'>{name}</span>
    </Button>
  ));
  CustomToggle.displayName = 'imageToggle';

  return (
    <div
      className='py-2 d-flex bg-primary align-items-center border-bottom border-3 border-secondary'
      style={{ height: '60px' }}
    >
      <div className='d-flex align-items-center text-light ps-3 pe-2'>
        <img
          src='/ram.svg'
          height={'48px'}
          alt='Colorado State University - CSU'
        />
        <a
          href='https://www.colostate.edu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='/csu_short.svg'
            className='d-none d-sm-block d-lg-none ms-1'
            height={'48px'}
            alt='Colorado State University - CSU'
          />
        </a>
        <a
          href='https://www.colostate.edu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='/csu_long.svg'
            className='d-none d-lg-block ms-1'
            height={'48px'}
            alt='Colorado State University - CSU'
          />
        </a>
      </div>
      <Breadcrumbs loggedIn={name !== null} />
      {name && picture && (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            {(role === 'superuser' || role === 'admin') && (
              <>
                <Dropdown.Item
                  className='small'
                  as={'button'}
                  onClick={() => navigate('/admin')}
                >
                  <i className='bi bi-person-badge-fill' />
                  <span className='ms-2'>Admin Tools</span>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}

            <Dropdown.Item
              className='small'
              as={'button'}
              onClick={() => navigate('/docs')}
            >
              <i className={`bi bi-journals`} />
              <span className='ms-2'>Docs</span>
            </Dropdown.Item>
            <Report name={name} />
            <Dropdown.Item
              className='small'
              as={'button'}
              onClick={() => navigate('/logout')}
            >
              <i className='bi bi-box-arrow-right' />
              <span className='ms-2'>Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default memo(Header);
