import { memo, useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  Button,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { getLogOutputs, getLogs } from '../../api/grade_logs_api';
import { toLocalTZ } from '../../util/date';
import { DocsHelp } from '../docs/Docs';
import SearchSelect from '../reusable/SearchSelect';
import { getStatusTD } from './AssignmentGrade';
import { DEFAULT_LOGS } from './Constants';

function GradeLogs({
  courseId,
  assignmentId,
  students,
  logsData,
  setLogsData,
}) {
  const [formData, setFormData] = useState(DEFAULT_LOGS);
  const [student, setStudent] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [applyDisabled, setApplyDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const { setShowModal } = useGlobalVars();

  const populateLogsTable = (page = pageNumber) => {
    setApplyDisabled(true);
    const filterData = { ...formData, student: student?.id ?? null };
    getLogs(courseId, assignmentId, filterData, page).then((res) => {
      setApplyDisabled(false);
      setErrMsg('');
      setValidated(false);
      setPageNumber(res.data.curr_page);
      setLastPage(res.data.total_pages);
      setLogsData(res.data.logs);
    });
  };

  useEffect(() => {
    populateLogsTable();
  }, [assignmentId]);

  const handleApply = (e) => {
    e.preventDefault();

    if (formData.from !== null) {
      if (formData.to === null) {
        setErrMsg('Both from and to must be entered!');
        return;
      } else {
        formData.from = new Date(formData.from);
        formData.to = new Date(formData.to);
        if (formData.to <= formData.from) {
          setErrMsg('The to date must be greater than the from date!');
          return;
        }
      }
    } else if (formData.to !== null) {
      setErrMsg('Both from and to must be entered!');
      return;
    }

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      populateLogsTable(1);
    }
  };

  const getStudentName = (id) => {
    if (id === -1) return 'Test Student';
    const result = students.find((obj) => obj.id === id);
    return result?.name;
  };

  const showDetailedLog = (log) => {
    getLogOutputs(courseId, assignmentId, log._id).then((res) => {
      log.comment = res.data.comment;
      log.stderr = res.data.stderr;
      log.student = getStudentName(log.student_id);
      setShowModal(
        <LogsModal log={log} hideModal={() => setShowModal(null)} />,
      );
    });
  };

  return (
    <>
      <Form
        onSubmit={handleApply}
        noValidate
        validated={validated}
        className='mx-3 p-1'
      >
        <Form.Label>
          Filter
          <DocsHelp at={'grade.logs.filter'} />
        </Form.Label>
        <div className='d-flex flex-row flex-lg-wrap flex-md-wrap w-75 small'>
          <div className='flex-fill px-1'>
            <Row>
              <Form.Label column sm='3'>
                Status
              </Form.Label>
              <Col sm='auto'>
                <Form.Select
                  size='sm'
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      status: e.target.value == 0 ? null : e.target.value,
                    })
                  }
                >
                  <option value={0}>All</option>
                  <option value={true}>Success</option>
                  <option value={false}>Fail</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm='3'>
                Student
              </Form.Label>
              <Col sm='auto'>
                <SearchSelect
                  setIdName={setStudent}
                  items={[
                    ...(students || []),
                    { id: -1, name: 'Test Student' },
                  ]}
                  placeholder={'All Students'}
                />
              </Col>
            </Row>
            <Row>
              <Form.Label column sm='3'>
                Per Page
              </Form.Label>
              <Col sm='auto'>
                <Form.Select
                  size='sm'
                  onChange={(e) =>
                    setFormData({ ...formData, perPage: e.target.value })
                  }
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
          <div className='flex-fill px-1'>
            <Row>
              <Form.Label column sm='3'>
                From
              </Form.Label>
              <Col sm='auto'>
                <Form.Control
                  size='sm'
                  type='datetime-local'
                  onChange={(e) =>
                    setFormData({ ...formData, from: e.target.value })
                  }
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm='3'>
                To
              </Form.Label>
              <Col sm='auto'>
                <Form.Control
                  size='sm'
                  type='datetime-local'
                  onChange={(e) =>
                    setFormData({ ...formData, to: e.target.value })
                  }
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm='3' />
              <Col sm='auto'>
                <Button
                  className='text-light'
                  variant='info'
                  size='sm'
                  type={'submit'}
                  disabled={applyDisabled}
                >
                  <i className='bi bi-funnel-fill'></i> Apply
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <span className='text-danger small'>{errMsg}</span>
      </Form>
      <hr className='mx-3' />
      <Form.Label className='ms-4'>
        Logs
        <DocsHelp at={'grade.logs.table'} />
      </Form.Label>
      <div className='px-4'>
        <Table
          striped
          bordered
          responsive
          hover
          size='sm'
          className='small vertical-middle'
        >
          <thead>
            <tr>
              <th>Status</th>
              <th>Graded At</th>
              <th>Submitted At</th>
              <th>Student Name</th>
              <th>Grade</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {logsData === null || typeof logsData === 'string' ? (
              <tr className='text-center'>
                <td colSpan={6}>{logsData}</td>
              </tr>
            ) : (
              logsData.map((log) => (
                <tr
                  className='pointer'
                  onClick={() => showDetailedLog(log)}
                  key={log._id}
                >
                  {getStatusTD(log.success)}
                  <td>{toLocalTZ(log.graded_at)}</td>
                  <td>{toLocalTZ(log.submitted_at)}</td>
                  <td>{getStudentName(log.student_id)}</td>
                  <td>{log.grade}</td>
                  <td>{log.duration.toFixed(1)}s</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      {lastPage > 0 && (
        <div className='d-flex justify-content-center'>
          <Pagination size='sm'>
            {pageNumber > 2 && (
              <Pagination.First
                linkClassName='text-info'
                onClick={() => populateLogsTable(1)}
              />
            )}
            {pageNumber > 1 && (
              <Pagination.Prev
                linkClassName='text-info'
                onClick={() => {
                  populateLogsTable(pageNumber - 1);
                }}
              />
            )}
            <Pagination.Item
              active
              linkClassName='bg-info border-info pointer'
              onClick={() => populateLogsTable(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
            {pageNumber < lastPage && (
              <Pagination.Next
                linkClassName='text-info'
                onClick={() => populateLogsTable(pageNumber + 1)}
              />
            )}
            {pageNumber < lastPage - 1 && (
              <Pagination.Last
                linkClassName='text-info'
                onClick={() => populateLogsTable(lastPage)}
              />
            )}
          </Pagination>
        </div>
      )}
    </>
  );
}

function LogsModal({ log, hideModal }) {
  return (
    <Modal
      show
      centered
      size={'lg'}
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header
        className='bg-primary text-light'
        closeButton
        closeVariant='white'
      >
        <b>Log Details</b>
      </Modal.Header>
      <Modal.Body
        style={{
          whiteSpace: 'pre-line',
          maxHeight: '70vh',
        }}
      >
        <Table
          striped
          bordered
          id='logsTable'
          size='sm'
          className='small bg-light'
          style={{ tableLayout: 'fixed' }}
        >
          <tbody>
            <tr>
              <td style={{ width: '30%' }}>
                Status of Running Grader on Submission
              </td>
              <td style={{ width: '70%' }}>
                {log.success ? 'SUCCESS' : 'FAILED'}
              </td>
            </tr>
            <tr>
              <td>Date & Time of Submission</td>
              <td>{toLocalTZ(log.submitted_at)}</td>
            </tr>
            <tr>
              <td>Date & Time Grader was Run</td>
              <td>{toLocalTZ(log.graded_at)}</td>
            </tr>
            <tr>
              <td>Student Name</td>
              <td>{log.student}</td>
            </tr>
            <tr>
              <td>Duration of Running the Grader</td>
              <td>{log.duration.toFixed(1) + 's'}</td>
            </tr>
            <tr>
              <td>Grade Received by Student</td>
              <td>{log.grade}</td>
            </tr>
            <tr>
              <td>Comment Received by Student</td>
              <td>{log?.comment}</td>
            </tr>
            <tr>
              <td>Logs/Errors Captured by Grader When Running Submission</td>
              <td>{log?.stderr}</td>
            </tr>
            {log.static_analysis !== null && (
              <tr>
                <td>Static Analysis Report</td>
                <td>
                  <NavLink
                    to={'/static-analysis/' + log.static_analysis.id}
                    onClick={() => hideModal()}
                  >
                    <i className='bi bi-box-arrow-up-right'></i>
                  </NavLink>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

export default memo(GradeLogs);
