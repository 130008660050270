import { useNavigate } from 'react-router-dom';
import '../../../index.scss';
import { memo, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  oauth2_response_url,
  saml2_authn_request_url,
} from '../../api/authentication_api';
import Stats from './Stats';

function Login({ name }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (name) {
      navigate('/courses', { replace: true });
    }
  }, [name]);

  window.__amp415__ = (v) =>
    (window.location.href = oauth2_response_url + '?token=' + v);

  return (
    <div className='w-100 d-flex flex-column align-items-center'>
      <div className='text-center pt-5 mt-5'>
        <h1 className='text-primary' style={{ fontSize: '80px' }}>
          <b>RamDesk</b>
        </h1>
        A suite of tools for managing your Canvas courses.
        <hr className='text-secondary border-2' style={{ opacity: 0.8 }} />
        <div className='d-flex gap-2'>
          <div className='w-75'>
            <Button
              variant='primary'
              size='lg'
              className='w-100 border-0 rounded-1 shadow pt-1'
              onClick={() => (window.location.href = saml2_authn_request_url)}
            >
              NetID Login
            </Button>
          </div>
          <div className='w-25'>
            <Button
              variant='light'
              size='lg'
              className='w-100 border-0  rounded-1 shadow pt-1'
              onClick={() => navigate('/docs')}
            >
              Docs
            </Button>
          </div>
        </div>
      </div>
      <Stats />
      {/* <p>
        Created by the Colorado State University Computer Science Department
      </p> */}
    </div>
  );
}

export default memo(Login);
